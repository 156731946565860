// src/pages/Home.js
import React from 'react';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Portfolio from '../components/Portfolio';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const Home = () => (
  <>
    <Hero />
    <About />
    {/*<Services />*/}
    {/*<Portfolio />*/}
    {/*<Testimonials />*/}
    {/*<Contact />*/}
    <Footer />
  </>
);

export default Home;
