import React from 'react';
import logoCom from '../assets/logo.png';
import './largeimage.css';

const Hero = () => {
  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="hero">
      
        <img src={logoCom} alt="Astro" className="logoT" />
        <p>Bringing surfaces to life with precision and quality sandblasting.</p>
        
        {/*<button onClick={scrollToContact}>Contact us</button>*/}
      
    </section>
  );
};

export default Hero;
